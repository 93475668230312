// Reboot
//

b,
strong {
  font-weight: $font-weight-bold;
}

@include rtl-only {
  dd {
    margin-right: 0;
  }
  caption {
    text-align: right;
  }
}

@if $dark-style {
  select:not([multiple]):not([size]),
  select[size="1"] {
    option {
      color: $black;
    }
  }
}

a.list-group-item,
.app-brand a,
a.sidenav-link,
a.navbar-search-cancel {
  text-decoration: none !important;
}
